import { Link } from "gatsby";
import React, { Component } from "react";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langOpen: false,
      lang: this.props.lang,
      hrefLang: this.props.hrefLang
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ langOpen: false });
    }
  }

  render() {
    const { langOpen, lang, hrefLang } = this.state;

    return (
      <header>
        <div className="main-container">
          <div className="top-line">
            <div className="content-holder">
              <Link
                className="logo-holder"
                id="imageLoader"
                to={`/${this.props.lang ? this.props.lang : "pl"}`}
              >
                <img
                  src={require("../images/logotype.svg")}
                  alt="Logotype ppp"
                />
              </Link>
              <div className="header-title">
                <p>
                  <strong>Projektowanie</strong> i <strong>wykonawstwo</strong>
                </p>
                <p>posadzek przemysłowych</p>
              </div>
            </div>

            <div className="buttons-holder" ref={this.setWrapperRef}>
              <button
                className={`btn-lang ${langOpen ? "active" : ""}`}
                onClick={() => this.setState({ langOpen: true })}
              >
                <img
                  src={require(`../images/flags/${lang}.svg`)}
                  alt="Logotype ppp"
                  style={{ width: "20px" }}
                />
                <span>{`${lang}`}</span>
              </button>
              <div className={`other-languages ${langOpen ? "open" : ""}`}>
                {hrefLang &&
                  hrefLang.map(
                    item =>
                      item.lang !== lang && (
                        <Link
                          key={lang}
                          className="btn-lang"
                          to={`${item.lang}/${item.slug}`}
                          onClick={() => this.setState({ langOpen: false })}
                        >
                          <img
                            src={require(`../images/flags/${item.lang}.svg`)}
                            alt="Logotype ppp"
                            style={{ width: "20px" }}
                          />
                          <span>{item.lang}</span>
                        </Link>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
